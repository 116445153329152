import get from 'lodash.get';

export const carrierAboutOrbitvu = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, '');
  const CTI = get(data, 'fieldCardTextImage.entity.fieldTextAndImage[0].entity', '');

  return {
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes' ? true : false,
    description: get(data, 'fieldCopy.processed', ''),
    video: {
      poster: {
        url: get(data, 'fieldPoster.url', ''),
        alt: get(data, 'fieldPoster.alt', ''),
        title: get(data, 'fieldPoster.title', ''),
        webp: get(data, 'fieldPoster.derivative.url', ''),
      },
      type: 'video',
      videoUrl: get(data, 'fieldVideoUrl.url.path', ''),
      schemaData: get(data, 'fieldName', undefined)
        ? {
            name: get(data, 'fieldName', ''),
            duration: get(data, 'fieldVideoDuration', ''),
            description: get(data, 'fieldVideoDescription', ''),
            uploadDate: get(data, 'entityCreated', ''),
          }
        : undefined,
    },
    cardTextImage: {
      headline: get(CTI, 'fieldHeadline.processed', ''),
      description: get(CTI, 'fieldDescription.processed', ''),
      image: get(CTI, 'fieldImage', undefined)
        ? {
            alt: get(CTI, 'fieldImage.alt', ''),
            title: get(CTI, 'fieldImage.title', ''),
            url: get(CTI, 'fieldImage.url', ''),
            webp: get(CTI, 'fieldImage.derivative.url'),
          }
        : undefined,
      video: get(CTI, 'fieldVideo.entity.url', undefined),
      videoUrl: get(CTI, 'fieldVideoUrl.url.path', undefined),
      label: get(CTI, 'fieldLabel', undefined)
        ? {
            title: get(CTI, 'fieldLabel', ''),
            url: get(CTI, 'fieldLabelLink.url.path', ''),
            color: get(CTI, 'fieldLabelColor', null),
          }
        : undefined,
      cta: get(CTI, 'fieldLinkCta', undefined)
        ? {
            label: get(CTI, 'fieldLinkCta.title', ''),
            url: get(CTI, 'fieldLinkCta.url.path', ''),
          }
        : undefined,
      imageOrVideo: get(CTI, 'fieldSelectImageOrVideo', 'image'),
      poster: get(CTI, 'fieldPoster', undefined)
        ? {
            alt: get(CTI, 'fieldPoster.alt', ''),
            title: get(CTI, 'fieldPoster.title', ''),
            url: get(CTI, 'fieldPoster.url', ''),
            webp: get(CTI, 'fieldPoster.derivative.url'),
          }
        : undefined,
    },
    imageWithCaption: get(data, 'fieldImageCarousel', []).map((item) => ({
      title: get(item, 'title', ''),
      image: {
        alt: get(item, 'alt', ''),
        url: get(item, 'url', ''),
        title: get(item, 'title', ''),
        webp: get(item, 'derivative.url', ''),
      },
    })),
  };
};
