import get from 'lodash.get';

// === Data === //
import { careerData } from '@graphql/Career';

// === Helpers === //
import { getNodeEntity } from '@utils';

// === Sections === //
import { mainBanner } from '@DTO/sections/MainBanner';
import { cardTextImage } from '@DTO/sections/CardTextImage';
import { contextImageWithCaption } from '@DTO/sections/ContextImageWithCaption';
import { metatags } from '@DTO/sections/Metatags';
import { ourCustomerLogo } from '@DTO/sections/OurCustomerLogo';
import { departmentsWithJobOffersList } from '@DTO/sections/career/DepartmentsWithJobOffersList';
import { carrierAboutOrbitvu } from '@DTO/sections/career/CarrierAboutOrbitvu';
import { breadcrumbs } from '@DTO/sections/Breadcrumbs';
import { newsletter } from '@DTO/sections/form/Newsletter';
import { getDataFromPreview } from '@helpers/form';
import { languagePath } from '@DTO/sections/LanguagePath';

export const CareerPageDTO = (uuid: string | undefined, data?: { drupalData: unknown }) => {
  let currentPageData: { drupalData: unknown };

  if (!data) {
    const careerDataQuery = careerData();
    currentPageData = careerDataQuery;
  } else {
    currentPageData = data;
  }

  const nodeEntity = getNodeEntity(uuid, currentPageData, data);

  const taxonomyEntity = get(currentPageData, 'drupalData.taxonomyTermQuery.entities', []);
  const allOffers = get(currentPageData, 'drupalData.allOffers.entities', []).map((offer) => ({
    department: get(offer, 'fieldCareersIntro.entity.fieldDepartamentName.entity.name', ''),
    title: get(offer, 'fieldCareersIntro.entity.fieldJobTitle', ''),
    place: get(offer, 'fieldCareersIntro.entity.fieldLocation', ''),
    ctaUrl: get(offer, 'path.alias', '/'),
  }));

  const formId = get(nodeEntity, 'fieldForm.entity.uuid', '');
  const additionalData = data
    ? {
        form: get(data, 'drupalData.form'),
      }
    : undefined;

  const queryData = {
    metaData: metatags(nodeEntity, 'fieldMetatags'),
    breadcrumbs: breadcrumbs(nodeEntity, []),
    stickedForm: get(nodeEntity, 'fieldFormOffer[0]', 'no') === 'yes',
    langPath: languagePath(nodeEntity, 'entityTranslations', 'entityMultisiteTranslations'),
    components: [
      mainBanner(nodeEntity, 'fieldMainBanner'),
      cardTextImage(nodeEntity, 'fieldCardTextImage'),
      ourCustomerLogo(nodeEntity, 'fieldOurCustomerLogo'),
      cardTextImage(nodeEntity, 'fieldCardTextImage2'),
      carrierAboutOrbitvu(nodeEntity, 'fieldCareerAboutOrbitvu'),
      departmentsWithJobOffersList(
        nodeEntity,
        taxonomyEntity,
        allOffers,
        'fieldDepartmentsWithJobOffer'
      ),
      contextImageWithCaption(nodeEntity, 'fieldContextImageWithCaption'),
      formId && formId !== '' && newsletter(formId, getDataFromPreview(additionalData)),
    ],
  };

  return {
    ...queryData,
  };
};
