import { replaceLangCodes } from '@utils';
import { graphql, useStaticQuery } from 'gatsby';

export const careerData = () => {
  const data = useStaticQuery(graphql`
    query careerQuery {
      drupalData {
        nodeQuery(filter: { conditions: { field: "type", value: "careers_page" } }, limit: 1000) {
          entities {
            ... on drupalData_NodeCareersPage {
              entityLabel

              fieldCardTextImage {
                entity {
                  ...ParagraphCardTextImage
                }
              }
              fieldCardTextImage2 {
                entity {
                  ...ParagraphCardTextImage2
                }
              }
              fieldCareerAboutOrbitvu {
                entity {
                  ...ParagraphCareerAboutOrbitvu
                }
              }
              fieldContextImageWithCaption {
                entity {
                  ...ParagraphContextImageWithCaption
                }
              }
              fieldDepartmentsWithJobOffer {
                entity {
                  ...ParagraphDepartmentsWithJobOffersList
                }
              }
              fieldMainBanner {
                entity {
                  ...ParagraphMainBannerVariant1
                  ...ParagraphMainBannerVariant2
                  ...ParagraphMainBannerVariant3
                }
              }
              fieldMetatags {
                entity {
                  ...ParagraphMetatags
                }
              }
              fieldOurCustomerLogo {
                entity {
                  ...ParagraphOurCustomerLogo
                }
              }
              fieldFormOffer
              fieldForm {
                entity {
                  uuid
                }
              }

              # important !!!
              path {
                alias
              }
              title
              uuid
              langcode {
                value
              }
              sticky: entityMultisiteEnabled
              status
              entityMultisiteTranslations {
                entity {
                  entityTranslations {
                    entityLabel
                    entityLanguage {
                      id
                    }
                    ... on drupalData_Node {
                      status
                      path {
                        alias
                      }
                    }
                  }
                }
              }
              entityTranslations {
                entityLabel
                entityLanguage {
                  id
                }
                ... on drupalData_Node {
                  status
                  path {
                    alias
                  }
                }
              }
            }
          }
        }

        allOffers: nodeQuery(
          filter: { conditions: { field: "type", value: "careers_details" } }
          limit: 10000
        ) {
          entities {
            ... on drupalData_NodeCareersDetails {
              entityLabel
              fieldCareersIntro {
                entity {
                  ...ParagraphCareersIntro
                }
              }
              path {
                alias
              }
              title
              uuid
              langcode {
                value
              }
              sticky: entityMultisiteEnabled
              status
              entityMultisiteTranslations {
                entity {
                  entityTranslations {
                    entityLabel
                    entityLanguage {
                      id
                    }
                    ... on drupalData_Node {
                      status
                      path {
                        alias
                      }
                    }
                  }
                }
              }
              entityTranslations {
                entityLabel
                entityLanguage {
                  id
                }
                ... on drupalData_Node {
                  status
                  path {
                    alias
                  }
                }
              }
            }
          }
        }

        taxonomyTermQuery(
          filter: { conditions: { field: "vid", value: "departments_with_job" } }
          limit: 1000
        ) {
          entities {
            ... on drupalData_TaxonomyTermDepartmentsWithJob {
              entityLabel
              fieldDescription {
                processed
              }
              fieldHeadline

              name
              weight
            }
          }
        }
      }
    }
  `);

  return replaceLangCodes(data);
};
