import React from 'react';

// === Components === //
import Helmet from 'react-helmet';
import Layout from '@components/layout';
import PageComponents from '@components/layout/PageComponents/PageComponents';

// === Content === //
import { CareerPageDTO } from '@DTO/CareerPageDTO';

// === Helpers === //
import { breadcrumbsObject, organizationObject } from '@helpers/schemaOrg';

// === Types === //
import { TemplatePageContextData } from '@type/index.d';

interface CareerPageProps {
  pageContext?: TemplatePageContextData;
}

const CareerPage = ({ pageContext }: CareerPageProps) => {
  const careerPage = CareerPageDTO(pageContext?.uuid);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbsObject(careerPage.breadcrumbs.list))}
        </script>
        <script type="application/ld+json">{JSON.stringify(organizationObject())}</script>
      </Helmet>
      <Layout
        metaData={careerPage.metaData}
        breadcrumbs={careerPage.breadcrumbs}
        stickedForm={careerPage.stickedForm}
        bannerContactInfo={undefined}
        navLangPath={careerPage.langPath}
      >
        <PageComponents components={careerPage.components} slug={`/${pageContext?.slug || ''}`} />
      </Layout>
    </>
  );
};

export default CareerPage;
