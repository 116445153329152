import get from 'lodash.get';
import { globalTranslations } from '@helpers/globalTranslations';

import { IDepartmentsWithJobOffersList } from '@components/sections/Career/DepartmentsWithJobOffersList/DepartmentsWithJobOffersList.d';

export const departmentsWithJobOffersList = (nodeData, taxonomyData, allOffers, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  const componentData: IDepartmentsWithJobOffersList = {
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    description: get(data, 'fieldDescription.processed', ''),
    lastTile: {
      title: get(data, 'fieldContactCopy', ''),
      linkLabel: get(data, 'fieldContactLinkCopy', ''),
      linkUrl: get(data, 'fieldContactLink', ''),
    },
    departments: taxonomyData.map(taxonomy => {
      const title = get(taxonomy, 'name', '');
      
      return {
        title,
        description: get(taxonomy, 'fieldDescription.processed', ''),
        offers: allOffers.filter(offer => offer.department === title).map(offer => ({
          ...offer,
          ctaLabel: globalTranslations('fieldLinkToJobOffer'),
        })),
        openPositionText: globalTranslations('fieldSingleOfferLink'),
        openPositionsText: globalTranslations('fieldMultipleOffersLink'),
        readAboutDepartmentLabel: globalTranslations('fieldNoOffersLink'),
        weight: get(taxonomy, 'weight', 0),
      }
    })
  }

  return componentData;
}